import { createRouter, createWebHistory } from "vue-router";
import { setModalVisibility, setSideMenuVisibility, createCookie } from "../helpers/helpers";
import { useConfigStore } from "../store/useConfigStore";
import { useMyStore } from "../store/useMyStore";
import { useCallsStore } from "../store/useCallsStore";

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
        {
            path: "/",
            name: "Home",
            component: () => {
                if (useConfigStore().config.home === "two") {
                    return import("../components/home/homeTwo.vue");
                } else if (useConfigStore().config.home === "splash") {
                    return import("../components/home/splash.vue");
                }
                return import("../components/home/home.vue");
            },
            meta: {
                showBanner: true,
            },
            children: [
                {
                    path: "/login",
                    name: "Login",
                    component: () => import("../components/modals/login.vue"),
                    meta: {
                        showModal: true,
                        loggedOut: true,
                        narrowContentModal: true,
                    },
                },
                {
                    path: "/register",
                    name: "Register",
                    component: () => import("../components/modals/register.vue"),
                    meta: {
                        showModal: true,
                        loggedOut: true,
                        narrowContentModal: true,
                        hideHeaderModal: true,
                    },
                },
                {
                    path: "/reward",
                    name: "Wheel Reward",
                    component: () => import("../components/modals/reward.vue"),
                    meta: {
                        showModal: true,
                        narrowContentModal: true,
                    },
                },
                {
                    path: "/account",
                    name: "Account",
                    component: () => import("../components/modals/account.vue"),
                    meta: {
                        showModal: true,
                        loggedIn: true,
                        narrowContentModal: true,
                    },
                },
                {
                    path: "/account/profile",
                    name: "My Profile",
                    component: () => import("../components/dynamic/accountPages/myProfile.vue"),
                    meta: {
                        showModal: true,
                        loggedIn: true,
                        showAccountBackButton: true,
                        narrowContentModal: true,
                    },
                },
                {
                    path: "/account/documents",
                    name: "My Documents",
                    component: () => import("../components/dynamic/accountPages/documents.vue"),
                    meta: {
                        showModal: true,
                        loggedIn: true,
                        showAccountBackButton: true,
                    },
                },
                {
                    path: "/account/bonuses",
                    name: "My Bonuses",
                    component: () => import("../components/dynamic/accountPages/myBonuses.vue"),
                    meta: {
                        showModal: true,
                        loggedIn: true,
                        showAccountBackButton: true,
                    },
                },
                {
                    path: "/account/history",
                    name: "My History",
                    component: () => import("../components/dynamic/accountPages/myHistory.vue"),
                    meta: {
                        showModal: true,
                        loggedIn: true,
                        showAccountBackButton: true,
                    },
                },
                {
                    path: "/account/responsible",
                    name: "Responsible",
                    component: () => import("../components/dynamic/accountPages/responsible.vue"),
                    meta: {
                        showModal: true,
                        loggedIn: true,
                        showAccountBackButton: true,
                    },
                },
                {
                    path: "/account/change-password",
                    name: "Change Password",
                    component: () => import("../components/dynamic/accountPages/changePassword.vue"),
                    meta: {
                        showModal: true,
                        loggedIn: true,
                        showAccountBackButton: true,
                        narrowContentModal: true,
                    },
                },
                {
                    path: "/limits",
                    name: "Set Limits",
                    component: () => import("../components/modals/limits.vue"),
                    meta: {
                        showModal: true,
                        loggedIn: true,
                    },
                },
                {
                    path: "/payments/:type",
                    name: "Payments",
                    component: () => import("../components/modals/payments.vue"),
                    props: true,
                    meta: {
                        showModal: true,
                        loggedIn: true,
                    },
                },
                {
                    path: "/transaction-status/:id/:status/",
                    name: "Payment Status",
                    component: () => import("../components/modals/transactionStatus.vue"),
                    props: true,
                    meta: {
                        showModal: true,
                        loggedIn: true,
                    },
                },
                {
                    path: "/game",
                    name: "Game",
                    component: () => import("../components/modals/game.vue"),
                    meta: {
                        showModal: true,
                    },
                },
                {
                    path: "/search/:searchBy?",
                    name: "Search",
                    component: () => import("../components/modals/search.vue"),
                    props: true,
                    meta: {
                        showModal: true,
                        fullScreenModal: true,
                    },
                },
                {
                    path: "/forgot-password",
                    name: "Forgot Password",
                    component: () => import("../components/modals/forgotPassword.vue"),
                    meta: {
                        showModal: true,
                        loggedOut: true,
                        narrowContentModal: true,
                    },
                },
                {
                    path: "/reset-password/:hash",
                    name: "Reset Password",
                    component: () => import("../components/modals/resetPassword.vue"),
                    props: true,
                    meta: {
                        showModal: true,
                        loggedOut: true,
                    },
                },
                {
                    path: "/email-verification/:code",
                    name: "Email Verification",
                    component: () => import("../components/modals/emailVerification.vue"),
                    props: true,
                    meta: {
                        showModal: true,
                        loggedOut: true,
                    },
                },
                {
                    path: "/activate/:code",
                    name: "Manual Email Verification",
                    component: () => import("../components/modals/emailVerification.vue"),
                    props: true,
                    meta: {
                        showModal: true,
                        loggedOut: true,
                    },
                },
                {
                    path: "/account-verification",
                    name: "Account Verification",
                    component: () => import("../components/modals/accountVerification.vue"),
                    meta: {
                        showModal: true,
                        loggedOut: true,
                    },
                },
                {
                    path: "/languages",
                    name: "Languages",
                    component: () => import("../components/modals/languages.vue"),
                    meta: {
                        showModal: true,
                        narrowContentModal: true,
                    },
                },
            ],
        },
        {
            path: "/lobbies/:lobbyType",
            name: "Lobbies",
            component: () => import("../components/games/lobbies.vue"),
            props: true,
        },
        {
            path: "/lobby/:lobby_id",
            name: "Lobby",
            component: () => import("../components/games/lobby.vue"),
            props: true,
        },
        {
            path: "/promotions",
            name: "Promotions",
            component: () => import("../components/promotions/promotions.vue"),
        },
        {
            path: "/vip",
            name: "Vip",
            component: () => import("../components/vip/vip.vue"),
        },
        {
            path: "/shop",
            name: "Shop",
            component: () => import("../components/shop/shop.vue"),
        },
        {
            path: "/gamification",
            name: "Gamification",
            component: () => import("../components/gamification/gamification.vue"),
        },
        {
            path: "/promotions/wheel",
            name: "Wheel",
            component: () => import("../components/gamification/wheelInfo.vue"),
            meta: {
                showBanner: true,
            },
        },
        {
            path: "/:pathMatch(.*)*",
            name: "NotFound",
            component: () => import("../components/home/home.vue"),
        },
    ],
});

router.beforeEach((to, from, next) => {
    const myStore = useMyStore();

    // Blocking any navigation except home if cookies were not accepted
    // if (myStore.showCookiePolicyPopUp === false || to.fullPath === "/") {
    if (to.params && to.params.scrollId) {
        // We are not scrolling to top instead we are scrolling to the element
    } else {
        $("html, body").animate({ scrollTop: 0 });
    }

    if (from.meta.showModal !== true) {
        setSideMenuVisibility(false);
    }
    if (to.meta.showModal !== true && from.meta.showModal === true) {
        // To route is not a modal, Since modal is currently opened we need to close it
        setModalVisibility(false);
    }

    if (myStore.loggedIn) {
        // User Logged In
        if (to.meta.loggedOut === true) {
            // Route is LoggedOut only
            myStore.setAlert({ text: "WARNING_NAVIGATION", classes: "warning" });
            return next(false);
        }
    } else {
        // User Logged Out
        if (to.meta.loggedIn === true) {
            // Route is LoggedIn only
            myStore.setAlert({ text: "WARNING_NAVIGATION", classes: "warning" });
            return next("/login");
        }
    }

    // pages and promotion routes are created dynamically, and it was resulting in a page not found warning when accessed directly
    // The warning was removed by matching the yet unexisted route with a catch all route named NotFound.
    // And when this happen, we set a slight delay to give some time for the dynamic routes to be creted, and replace the route
    if (to.name === "NotFound" && (to.path.indexOf("/pages/") !== -1 || to.path.indexOf("/promotions/") !== -1)) {
        const fullPath = to.fullPath;
        setTimeout(function () {
            router.replace(fullPath);
        }, 100);
    } else {
        return next();
    }
    // } else {
    //     router.replace("/");
    // }
});

router.afterEach((to, from) => {
    const config = useConfigStore().config;
    const unFormattedTotalBalance = useCallsStore().unFormattedTotalBalance;

    // Check path and name and set the browser title to the according page
    if (to.path && to.name) {
        document.title = config.originalName ? `${config.originalName} - ${to.name}` : to.name;

        // Set the canonical link for SEO purposes
        const canonicalLink = document.getElementById("canonicalLink");
        if (canonicalLink !== null) {
            canonicalLink.href = `${config.originalDomain}${to.path}`;
        }
    }

    // CreateCookies for user activity purposes
    // lnt = lastNavigationTime
    createCookie("lnt", new Date(), 1);
    // lntb = lastNavigationTotalBalance
    createCookie("lntb", unFormattedTotalBalance, 1);
});

export default router;
